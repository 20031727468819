






























































































































































import { useProductCategory } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AccountingAccountResponseDto } from "@/models/interface/AccountingAccount.interface";
import { RequestProductCategory } from "@/models/interface/product.interface";
import { ProductCategoryReportResponseDto } from "@/models/interface/ProductCategoryReport.interface";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import SelectAccount from "./SelectAccount.vue";

type FormValue = {
  name: string;
  costOfSalesAccountId: string;
  inventoryAccountId: string;
  expensePurchaseAccountId: string;
  purchaseReturnAccountId: string;
  purchaseDiscountAccountId: string;
  unbilledAccountId: string;
  salesAccountId: string;
  salesReturnAccountId: string;
  salesDiscountAccountId: string;
  assetCostAccountId: string;
  assetClearingAccountId: string;
  assetDepreciationAccountId: string;
  assetAccumulationAccountId: string;
  costOfSalesAccountName: string;
  inventoryAccountName: string;
  expensePurchaseAccountName: string;
  purchaseReturnAccountName: string;
  purchaseDiscountAccountName: string;
  unbilledAccountName: string;
  salesAccountName: string;
  salesReturnAccountName: string;
  salesDiscountAccountName: string;
  assetCostAccountName: string;
  assetClearingAccountName: string;
  assetDepreciationAccountName: string;
  assetAccumulationAccountName: string;
};
type FieldSource = keyof FormValue;

@Component({
  components: {
    SelectAccount,
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  @Ref("form") form!: FormModel;
  formRules = {
    name: {
      label: "lbl_category",
      name: "Category",
      placeholder: "lbl_category",
    },
    costOfSalesAccountId: {
      label: "lbl_cost_of_sales_account",
      name: "costOfSalesAccountId",
      placeholder: "lbl_cost_of_sales_account",
    },
    inventoryAccountId: {
      label: "lbl_inventory_account",
      name: "inventoryAccountId",
      placeholder: "lbl_inventory_account",
    },
    expensePurchaseAccountId: {
      label: "lbl_expense_purchase_account",
      name: "expensePurchaseAccountId",
      placeholder: "lbl_expense_purchase_account",
    },
    purchaseReturnAccountId: {
      label: "lbl_purchase_return_account",
      name: "purchaseReturnAccountId",
      placeholder: "lbl_purchase_return_account",
    },
    purchaseDiscountAccountId: {
      label: "lbl_purchase_discount_account",
      name: "purchaseDiscountAccountId",
      placeholder: "lbl_purchase_discount_account",
    },
    unbilledAccountId: {
      label: "lbl_unbilled_account",
      name: "unbilledAccountId",
      placeholder: "lbl_unbilled_account",
    },
    salesAccountId: {
      label: "lbl_sales_account",
      name: "salesAccountId",
      placeholder: "lbl_sales_account",
    },
    salesReturnAccountId: {
      label: "lbl_sales_return_account",
      name: "salesReturnAccountId",
      placeholder: "lbl_sales_return_account",
    },
    salesDiscountAccountId: {
      label: "lbl_sales_discount_account",
      name: "salesDiscountAccountId",
      placeholder: "lbl_sales_discount",
    },
    assetCostAccountId: {
      label: "lbl_asset_cost_account",
      name: "assetCostAccountId",
      placeholder: "lbl_asset_cost_account",
    },
    assetClearingAccountId: {
      label: "lbl_asset_clearing_account",
      name: "assetClearingAccountId",
      placeholder: "lbl_asset_clearing_account",
    },
    assetDepreciationAccountId: {
      label: "lbl_asset_depreciation_account",
      name: "assetDepreciationAccountId",
      placeholder: "lbl_asset_depreciation_account",
    },
    assetAccumulationAccountId: {
      label: "lbl_asset_accumulation_account",
      name: "assetAccumulationAccountId",
      placeholder: "lbl_asset_accumulation_account",
    },
  };
  formModel: FormValue = {
    name: "",
    costOfSalesAccountId: "",
    inventoryAccountId: "",
    expensePurchaseAccountId: "",
    purchaseReturnAccountId: "",
    purchaseDiscountAccountId: "",
    unbilledAccountId: "",
    salesAccountId: "",
    salesReturnAccountId: "",
    salesDiscountAccountId: "",
    assetCostAccountId: "",
    assetClearingAccountId: "",
    assetDepreciationAccountId: "",
    assetAccumulationAccountId: "",

    costOfSalesAccountName: "",
    inventoryAccountName: "",
    expensePurchaseAccountName: "",
    purchaseReturnAccountName: "",
    purchaseDiscountAccountName: "",
    unbilledAccountName: "",
    salesAccountName: "",
    salesReturnAccountName: "",
    salesDiscountAccountName: "",
    assetCostAccountName: "",
    assetClearingAccountName: "",
    assetDepreciationAccountName: "",
    assetAccumulationAccountName: "",
  };
  rules = {
    name: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    costOfSalesAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    inventoryAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    expensePurchaseAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    purchaseReturnAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    purchaseDiscountAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    unbilledAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesReturnAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesDiscountAccountName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    assetCostAccountId: [],
    assetClearingAccountId: [],
    assetDepreciationAccountId: [],
    assetAccumulationAccountId: [],
  };

  docId = "";
  loading = {
    submit: false,
  };

  created(): void {
    this.docId = this.$route.params.id || "";
  }

  mounted(): void {
    if (this.docId) {
      this.getDetail(this.docId);
    }
  }

  getDetail(id: string): void {
    const { findAllRemastered } = useProductCategory();

    // get detail part category using secure id from get list API
    // intended. no API dedicated to get detail Part category
    findAllRemastered({ search: "secureId~" + id }).then(
      (res: Pagination<ProductCategoryReportResponseDto>) => {
        const { data } = res;
        const [detail] = data;
        if (!detail) return;

        this.setForm(detail);
      }
    );
  }

  setForm(data: ProductCategoryReportResponseDto): void {
    this.formModel = {
      ...data,
    };
  }

  updateMeta(
    data: Option<AccountingAccountResponseDto> | undefined,
    source: FieldSource
  ): void {
    this.formModel[source] = (data?.key as string) || "";
  }

  handleSubmit(): void {
    this.form.validate((valid: boolean) => {
      if (!valid) return;

      if (this.docId) {
        this.handleUpdate(this.docId);
      } else {
        this.handleCreate();
      }
    });
  }

  buildReqDto(): RequestProductCategory {
    const { initReqCreateDto } = useProductCategory();
    const req: RequestProductCategory = initReqCreateDto();

    req.name = this.formModel.name;
    req.costOfSalesAccountId = this.formModel.costOfSalesAccountId || "";
    req.inventoryAccountId = this.formModel.inventoryAccountId || "";
    req.expensePurchaseAccountId =
      this.formModel.expensePurchaseAccountId || "";
    req.purchaseReturnAccountId = this.formModel.purchaseReturnAccountId || "";
    req.purchaseDiscountAccountId =
      this.formModel.purchaseDiscountAccountId || "";
    req.assetCostAccountId = this.formModel.assetCostAccountId || "";
    req.assetDepreciationAccountId =
      this.formModel.assetDepreciationAccountId || "";
    req.assetAccumulationAccountId =
      this.formModel.assetAccumulationAccountId || "";
    req.unbilledAccountId = this.formModel.unbilledAccountId || "";
    req.salesAccountId = this.formModel.salesAccountId || "";
    req.salesReturnAccountId = this.formModel.salesReturnAccountId || "";
    req.salesDiscountAccountId = this.formModel.salesDiscountAccountId || "";
    req.assetClearingAccountId = this.formModel.assetClearingAccountId || "";

    return req;
  }

  handleCreate(): void {
    const { create } = useProductCategory();
    const req: RequestProductCategory = this.buildReqDto();

    this.loading.submit = true;
    create(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: this.formModel.name,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleUpdate(id: string): void {
    const { update } = useProductCategory();
    const req: RequestProductCategory = this.buildReqDto();

    this.loading.submit = true;
    update(id, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: this.formModel.name,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.part-category",
      query: { ...this.$route.query },
    });
  }
}
